export default [
    {value: 'AK', text: "ALASKA"},
    {value: 'AL', text: "ALABAMA"},
    {value: 'AR', text: "ARKANSAS"},
    {value: 'AS', text: "AMERICAN SAMOA"},
    {value: 'AZ', text: "ARIZONA"},
    {value: 'CA', text: "CALIFORNIA"},
    {value: 'CO', text: "COLORADO"},
    {value: 'CT', text: "CONNECTICUT"},
    {value: 'DC', text: "DISTRICT OF COLUMBIA"},
    {value: 'DE', text: "DELAWARE"},
    {value: 'FL', text: "FLORIDA"},
    {value: 'FM', text: "MICRONESIA, FEDERATED STATES OF"},
    {value: 'GA', text: "GEORGIA"},
    {value: 'GU', text: "GUAM"},
    {value: 'HI', text: "HAWAII"},
    {value: 'IA', text: "IOWA"},
    {value: 'ID', text: "IDAHO"},
    {value: 'IL', text: "ILLINOIS"},
    {value: 'IN', text: "INDIANA"},
    {value: 'KS', text: "KANSAS"},
    {value: 'KY', text: "KENTUCKY"},
    {value: 'LA', text: "LOUISIANA"},
    {value: 'MA', text: "MASSACHUSETTS"},
    {value: 'MD', text: "MARYLAND"},
    {value: 'ME', text: "MAINE"},
    {value: 'MH', text: "MARSHALL ISLANDS"},
    {value: 'MI', text: "MICHIGAN"},
    {value: 'MN', text: "MINNESOTA"},
    {value: 'MO', text: "MISSOURI"},
    {value: 'MP', text: "MARIANA ISLANDS, NORTHERN"},
    {value: 'MS', text: "MISSISSIPPI"},
    {value: 'MT', text: "MONTANA"},
    {value: 'NC', text: "NORTH CAROLINA"},
    {value: 'ND', text: "NORTH DAKOTA"},
    {value: 'NE', text: "NEBRASKA"},
    {value: 'NH', text: "NEW HAMPSHIRE"},
    {value: 'NJ', text: "NEW JERSEY"},
    {value: 'NM', text: "NEW MEXICO"},
    {value: 'NV', text: "NEVADA"},
    {value: 'NY', text: "NEW YORK"},
    {value: 'OH', text: "OHIO"},
    {value: 'OK', text: "OKLAHOMA"},
    {value: 'OR', text: "OREGON"},
    {value: 'PA', text: "PENNSYLVANIA"},
    {value: 'PR', text: "PUERTO RICO"},
    {value: 'PW', text: "PALAU"},
    {value: 'RI', text: "RHODE ISLAND"},
    {value: 'SC', text: "SOUTH CAROLINA"},
    {value: 'SD', text: "SOUTH DAKOTA"},
    {value: 'TN', text: "TENNESSEE"},
    {value: 'TX', text: "TEXAS"},
    {value: 'UT', text: "UTAH"},
    {value: 'VA', text: "VIRGINIA"},
    {value: 'VI', text: "VIRGIN ISLANDS"},
    {value: 'VT', text: "VERMONT"},
    {value: 'WA', text: "WASHINGTON"},
    {value: 'WI', text: "WISCONSIN"},
    {value: 'WV', text: "WEST VIRGINIA"},
    {value: 'WY', text: "WYOMING"},
    {value: 'ZZ', text: "Foreign Country"},
]
