<template>
    <admin-dashboard-layout>
        <h1>Dentists</h1>
        <v-row>
            <v-col cols="12" md="3">
                <v-text-field
                    label="NPI"
                    v-model.number="filter.npi"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-autocomplete
                    clearable
                    label="Country"
                    item-text="text"
                    :items="countries"
                    item-value="value"
                    v-model="filter.country"
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
                <v-autocomplete
                    clearable
                    label="U.S state"
                    :items="states"
                    item-text="text"
                    item-value="value"
                    v-model="filter.state"
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" class="d-flex">
                <v-btn depressed color="error" class="flex-grow-1 mt-5 mr-2" @click="reset" small>
                    Reset
                </v-btn>
                <v-btn depressed color="primary" class="flex-grow-1 mt-5 ml-2" @click="search" small>
                    Filter
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            disable-sort
            :items="dentists"
            :loading="loading"
            :headers="headers"
            :items-per-page="5"
            class="elevation-1"
            loading-text="Loading... Please wait"
            :server-items-length="pagination.total"
            :options="{
                page: pagination.page,
                itemsPerPage: pagination.itemsPerPage,
            }"
            :footer-props="{
                disablePagination: loading,
                itemsPerPageOptions: pagination.itemsPerPageOptions,
            }"
            @click:row="handleClickRow"
            @update:options="handlePaginate"
        />
    </admin-dashboard-layout>
</template>

<script>
import states from "../../../configs/states";
import Dentist from "../../../models/Dentist";
import countries from "../../../configs/countries";
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";

export default {
    name: "Index",
    components: {AdminDashboardLayout},
    data() {
        return {
            loading: false,
            dentists: [],
            filter: {
                country: null,
                state: null,
                npi: null,
            },
            pagination: {
                page: 1,
                total: 0,
                itemsPerPage: 10,
                itemsPerPageOptions: [5, 10, 20, 30, 50],
            },
        }
    },
    computed: {
        states() {
            return states;
        },
        countries() {
            return countries;
        },
        headers() {
            return [
                {text: '# NPI', align: 'start', value: 'npi',},
                {text: 'Provider Name', value: 'provider_name'},
            ]
        }
    },
    methods: {
        reset() {
            this.filter = {country: null, state: null, npi: null}
            this.fetchDentists({page: 1, itemsPerPage: this.pagination.itemsPerPage})
        },
        search() {
            this.fetchDentists({page: 1, itemsPerPage: this.pagination.itemsPerPage})
        },
        handleClickRow(dentist) {
            this.$router.push({
                name: 'admin.dentists.show',
                params: {id: dentist.id},
                query: {...this.$route.query},
            })
        },
        fetchDentists({page, itemsPerPage} = {}) {
            this.loading = true
            return Dentist.page(page).limit(itemsPerPage)
                .where('country', this.filter.country)
                .where('state', this.filter.state)
                .where('npi', this.filter.npi)
                .get()
                .then(({data, ...meta}) => {
                    this.dentists = data
                    this.pagination.total = meta.total
                    this.pagination.page = meta.current_page
                })
                .catch(() => {
                }).finally(() => this.loading = false)
        },
        async handlePaginate(params) {
            const page = params.page
            const itemsPerPage = params.itemsPerPage
            const routeQuery = this.$route.query
            this.pagination.itemsPerPage = itemsPerPage
            this.pagination.page = params.page
            await this.fetchDentists(params)
            const newQuery = {}
            if (routeQuery.page !== page.toString()) {
                newQuery.page = page
            }
            if (routeQuery.perpage !== itemsPerPage.toString()) {
                newQuery.perpage = itemsPerPage
            }
            if (Object.keys(newQuery).length) {
                this.$router.replace({query: {...this.$route.query, ...newQuery}})
            }
        },
    },
    async created() {
        const {page, perpage} = this.$route.query
        this.pagination.page = /^[0-9]*$/.test(page) ? +page : 1
        this.pagination.itemsPerPage = /^[0-9]*$/.test(perpage) ? +perpage : 10
    },
}
</script>

<style scoped>

</style>
