export default [
    {value: 'AD', text: "ANDORRA"},
    {value: 'AE', text: "UNITED ARAB EMIRATES"},
    {value: 'AF', text: "AFGHANISTAN"},
    {value: 'AG', text: "ANTIGUA AND BARBUDA"},
    {value: 'AI', text: "ANGUILLA"},
    {value: 'AL', text: "ALBANIA"},
    {value: 'AM', text: "ARMENIA"},
    {value: 'AN', text: "NETHERLANDS ANTILLES"},
    {value: 'AO', text: "ANGOLA"},
    {value: 'AQ', text: "ANTARCTICA"},
    {value: 'AR', text: "ARGENTINA"},
    {value: 'AT', text: "AUSTRIA"},
    {value: 'AU', text: "AUSTRALIA"},
    {value: 'AW', text: "ARUBA"},
    {value: 'AX', text: "ALAND ISLANDS"},
    {value: 'AZ', text: "AZERBAIJAN"},
    {value: 'BA', text: "BOSNIA AND HERZEGOVINA"},
    {value: 'BB', text: "BARBADOS"},
    {value: 'BD', text: "BANGLADESH"},
    {value: 'BE', text: "BELGIUM"},
    {value: 'BF', text: "BURKINA FASO"},
    {value: 'BG', text: "BULGARIA"},
    {value: 'BH', text: "BAHRAIN"},
    {value: 'BI', text: "BURUNDI"},
    {value: 'BJ', text: "BENIN"},
    {value: 'BM', text: "BERMUDA"},
    {value: 'BN', text: "BRUNEI DARUSSALAM"},
    {value: 'BO', text: "BOLIVIA"},
    {value: 'BR', text: "BRAZIL"},
    {value: 'BS', text: "BAHAMAS"},
    {value: 'BT', text: "BHUTAN"},
    {value: 'BV', text: "BOUVET ISLAND"},
    {value: 'BW', text: "BOTSWANA"},
    {value: 'BY', text: "BELARUS"},
    {value: 'BZ', text: "BELIZE"},
    {value: 'CA', text: "CANADA"},
    {value: 'CC', text: "COCOS (KEELING) ISLANDS"},
    {value: 'CD', text: "CONGO, THE DEMOCRATIC REPUBLIC OF THE"},
    {value: 'CF', text: "CENTRAL AFRICAN REPUBLIC"},
    {value: 'CG', text: "CONGO"},
    {value: 'CH', text: "SWITZERLAND"},
    {value: 'CI', text: "CÔTE D'IVOIRE"},
    {value: 'CK', text: "COOK ISLANDS"},
    {value: 'CL', text: "CHILE"},
    {value: 'CM', text: "CAMEROON"},
    {value: 'CN', text: "CHINA"},
    {value: 'CO', text: "COLOMBIA"},
    {value: 'CR', text: "COSTA RICA"},
    {value: 'CS', text: "SERBIA AND MONTENEGRO"},
    {value: 'CU', text: "CUBA"},
    {value: 'CV', text: "CAPE VERDE"},
    {value: 'CX', text: "CHRISTMAS ISLAND"},
    {value: 'CY', text: "CYPRUS"},
    {value: 'CZ', text: "CZECH REPUBLIC"},
    {value: 'DE', text: "GERMANY"},
    {value: 'DJ', text: "DJIBOUTI"},
    {value: 'DK', text: "DENMARK"},
    {value: 'DM', text: "DOMINICA"},
    {value: 'DO', text: "DOMINICAN REPUBLIC"},
    {value: 'DZ', text: "ALGERIA"},
    {value: 'EC', text: "ECUADOR"},
    {value: 'EE', text: "ESTONIA"},
    {value: 'EG', text: "EGYPT"},
    {value: 'EH', text: "WESTERN SAHARA"},
    {value: 'ER', text: "ERITREA"},
    {value: 'ES', text: "SPAIN"},
    {value: 'ET', text: "ETHIOPIA"},
    {value: 'FI', text: "FINLAND"},
    {value: 'FJ', text: "FIJI"},
    {value: 'FK', text: "FALKLAND ISLANDS (MALVINAS)"},
    {value: 'FO', text: "FAROE ISLANDS"},
    {value: 'FR', text: "FRANCE"},
    {value: 'GA', text: "GABON"},
    {value: 'GB', text: "GREAT BRITAIN (UK)"},
    {value: 'GD', text: "GRENADA"},
    {value: 'GE', text: "GEORGIA"},
    {value: 'GF', text: "FRENCH GUIANA"},
    {value: 'GG', text: "GUERNSEY"},
    {value: 'GH', text: "GHANA"},
    {value: 'GI', text: "GIBRALTAR"},
    {value: 'GL', text: "GREENLAND"},
    {value: 'GM', text: "GAMBIA"},
    {value: 'GN', text: "GUINEA"},
    {value: 'GP', text: "GUADELOUPE"},
    {value: 'GQ', text: "EQUATORIAL GUINEA"},
    {value: 'GR', text: "GREECE"},
    {value: 'GS', text: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS"},
    {value: 'GT', text: "GUATEMALA"},
    {value: 'GW', text: "GUINEA-BISSAU"},
    {value: 'GY', text: "GUYANA"},
    {value: 'HK', text: "HONG KONG"},
    {value: 'HM', text: "HEARD ISLAND AND MCDONALD ISLANDS"},
    {value: 'HN', text: "HONDURAS"},
    {value: 'HR', text: "CROATIA"},
    {value: 'HT', text: "HAITI"},
    {value: 'HU', text: "HUNGARY"},
    {value: 'ID', text: "INDONESIA"},
    {value: 'IE', text: "IRELAND"},
    {value: 'IL', text: "ISRAEL"},
    {value: 'IM', text: "ISLE OF MAN"},
    {value: 'IN', text: "INDIA"},
    {value: 'IO', text: "BRITISH INDIAN OCEAN TERRITORY"},
    {value: 'IQ', text: "IRAQ"},
    {value: 'IR', text: "IRAN, ISLAMIC REPUBLIC OF"},
    {value: 'IS', text: "ICELAND"},
    {value: 'IT', text: "ITALY"},
    {value: 'JE', text: "JERSEY"},
    {value: 'JM', text: "JAMAICA"},
    {value: 'JO', text: "JORDAN"},
    {value: 'JP', text: "JAPAN"},
    {value: 'KE', text: "KENYA"},
    {value: 'KG', text: "KYRGYZSTAN"},
    {value: 'KH', text: "CAMBODIA"},
    {value: 'KI', text: "KIRIBATI"},
    {value: 'KM', text: "COMOROS"},
    {value: 'KN', text: "SAINT KITTS AND NEVIS"},
    {value: 'KP', text: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF"},
    {value: 'KR', text: "KOREA, REPUBLIC OF"},
    {value: 'KW', text: "KUWAIT"},
    {value: 'KY', text: "CAYMAN ISLANDS"},
    {value: 'KZ', text: "KAZAKHSTAN"},
    {value: 'LA', text: "LAO PEOPLE'S DEMOCRATIC REPUBLIC"},
    {value: 'LB', text: "LEBANON"},
    {value: 'LC', text: "SAINT LUCIA"},
    {value: 'LI', text: "LIECHTENSTEIN"},
    {value: 'LK', text: "SRI LANKA"},
    {value: 'LR', text: "LIBERIA"},
    {value: 'LS', text: "LESOTHO"},
    {value: 'LT', text: "LITHUANIA"},
    {value: 'LU', text: "LUXEMBOURG"},
    {value: 'LV', text: "LATVIA"},
    {value: 'LY', text: "LIBYAN ARAB JAMAHIRIYA"},
    {value: 'MA', text: "MOROCCO"},
    {value: 'MC', text: "MONACO"},
    {value: 'MD', text: "MOLDOVA, REPUBLIC OF"},
    {value: 'MG', text: "MADAGASCAR"},
    {value: 'MK', text: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF"},
    {value: 'ML', text: "MALI"},
    {value: 'MM', text: "MYANMAR"},
    {value: 'MN', text: "MONGOLIA"},
    {value: 'MO', text: "MACAO"},
    {value: 'MQ', text: "MARTINIQUE"},
    {value: 'MR', text: "MAURITANIA"},
    {value: 'MS', text: "MONTSERRAT"},
    {value: 'MT', text: "MALTA"},
    {value: 'MU', text: "MAURITIUS"},
    {value: 'MV', text: "MALDIVES"},
    {value: 'MW', text: "MALAWI"},
    {value: 'MX', text: "MEXICO"},
    {value: 'MY', text: "MALAYSIA"},
    {value: 'MZ', text: "MOZAMBIQUE"},
    {value: 'NA', text: "NAMIBIA"},
    {value: 'NC', text: "NEW CALEDONIA"},
    {value: 'NE', text: "NIGER"},
    {value: 'NF', text: "NORFOLK ISLAND"},
    {value: 'NG', text: "NIGERIA"},
    {value: 'NI', text: "NICARAGUA"},
    {value: 'NL', text: "NETHERLANDS"},
    {value: 'NO', text: "NORWAY"},
    {value: 'NP', text: "NEPAL"},
    {value: 'NR', text: "NAURU"},
    {value: 'NU', text: "NIUE"},
    {value: 'NZ', text: "NEW ZEALAND"},
    {value: 'OM', text: "OMAN"},
    {value: 'PA', text: "PANAMA"},
    {value: 'PE', text: "PERU"},
    {value: 'PF', text: "FRENCH POLYNESIA"},
    {value: 'PG', text: "PAPUA NEW GUINEA"},
    {value: 'PH', text: "PHILIPPINES"},
    {value: 'PK', text: "PAKISTAN"},
    {value: 'PL', text: "POLAND"},
    {value: 'PM', text: "SAINT PIERRE AND MIQUELON"},
    {value: 'PN', text: "PITCAIRN"},
    {value: 'PS', text: "PALESTINIAN TERRITORY, OCCUPIED"},
    {value: 'PT', text: "PORTUGAL"},
    {value: 'PY', text: "PARAGUAY"},
    {value: 'QA', text: "QATAR"},
    {value: 'RE', text: "REUNION"},
    {value: 'RO', text: "ROMANIA"},
    {value: 'RU', text: "RUSSIAN FEDERATION"},
    {value: 'RW', text: "RWANDA"},
    {value: 'SA', text: "SAUDI ARABIA"},
    {value: 'SB', text: "SOLOMON ISLANDS"},
    {value: 'SC', text: "SEYCHELLES"},
    {value: 'SD', text: "SUDAN"},
    {value: 'SE', text: "SWEDEN"},
    {value: 'SG', text: "SINGAPORE"},
    {value: 'SH', text: "SAINT HELENA"},
    {value: 'SI', text: "SLOVENIA"},
    {value: 'SJ', text: "SVALBARD AND JAN MAYEN"},
    {value: 'SK', text: "SLOVAKIA"},
    {value: 'SL', text: "SIERRA LEONE"},
    {value: 'SM', text: "SAN MARINO"},
    {value: 'SN', text: "SENEGAL"},
    {value: 'SO', text: "SOMALIA"},
    {value: 'SR', text: "SURINAME"},
    {value: 'ST', text: "SAO TOME AND PRINCIPE"},
    {value: 'SV', text: "EL SALVADOR"},
    {value: 'SY', text: "SYRIAN ARAB REPUBLIC"},
    {value: 'SZ', text: "SWAZILAND"},
    {value: 'TC', text: "TURKS AND CAICOS ISLANDS"},
    {value: 'TD', text: "CHAD"},
    {value: 'TF', text: "FRENCH SOUTHERN TERRITORIES"},
    {value: 'TG', text: "TOGO"},
    {value: 'TH', text: "THAILAND"},
    {value: 'TJ', text: "TAJIKISTAN"},
    {value: 'TK', text: "TOKELAU"},
    {value: 'TL', text: "TIMOR-LESTE"},
    {value: 'TM', text: "TURKMENISTAN"},
    {value: 'TN', text: "TUNISIA"},
    {value: 'TO', text: "TONGA"},
    {value: 'TR', text: "TURKEY"},
    {value: 'TT', text: "TRINIDAD AND TOBAGO"},
    {value: 'TV', text: "TUVALU"},
    {value: 'TW', text: "TAIWAN"},
    {value: 'TZ', text: "TANZANIA, UNITED REPUBLIC OF"},
    {value: 'UA', text: "UKRAINE"},
    {value: 'UG', text: "UGANDA"},
    {value: 'UM', text: "UNITED STATES MINOR OUTLYING ISLANDS"},
    {value: 'US', text: "UNITED STATES"},
    {value: 'UY', text: "URUGUAY"},
    {value: 'UZ', text: "UZBEKISTAN"},
    {value: 'VA', text: "HOLY SEE (VATICAN CITY STATE)"},
    {value: 'VC', text: "SAINT VINCENT AND THE GRENADINES"},
    {value: 'VE', text: "VENEZUELA"},
    {value: 'VG', text: "VIRGIN ISLANDS, BRITISH"},
    {value: 'VN', text: "VIET NAM"},
    {value: 'VU', text: "VANUATU"},
    {value: 'WF', text: "WALLIS AND FUTUNA"},
    {value: 'WS', text: "SAMOA"},
    {value: 'XK', text: "KOSOVO"},
    {value: 'YE', text: "YEMEN"},
    {value: 'YT', text: "MAYOTTE"},
    {value: 'ZA', text: "SOUTH AFRICA"},
    {value: 'ZM', text: "ZAMBIA"},
    {value: 'ZW', text: "ZIMBABWE"},
]
